import React,{useEffect} from 'react';
import bookmark from '../../../../assets/bookmarks.svg';
import bookmarked from '../../../../assets/bookmarkblue.svg';
import './NewsPage.scss';
import Pagination from '../Pagination/Pagination';
import NoResult from '../NoResult/NoResult';
import axios from 'axios';
import { useSelector } from "react-redux";
import Tooltip from '../tooltip/Tooltip';


const NewsPage = ({news,header,total,onPageChange,records,reloadPage,currentPage}) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;

    function formatDate(dateString) {
        const date = new Date(dateString); 
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const addBookmark = async (value) => {

       
          try {
           
            const config = {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
              },
            };
      
            axios
              .get(`${baseUrl}news/${value}/bookmark`,config)
              .then((res) => {
                  console.log(res)
                  alert(res.data.message)
                  reloadPage()
                 
              })
              .catch((error) => {
               
                if (error.response) {
                  if (error.response.status === 401) {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                  } else {
                    alert(error.response.data.message);
                  }
                } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
                } else {
                  alert("An error occurred. Please try again later.");
                }
              });
          }
          catch (error) {
            console.log("error", error);
          }
        
         
      }


    const goToNews = async (value) => {

       
          try {
           
            const config = {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
              },
            };
      
            axios
              .get(`${baseUrl}news/${value}/url`,config)
              .then((res) => {
                  console.log(res.data.data.url)
                  window.open(res.data.data.url, '_blank');
                 
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status === 401) {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                  } else {
                    alert(error.response.data.message);
                  }
                } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
                } else {
                  alert("An error occurred. Please try again later.");
                }
              });
          }
          catch (error) {
            console.log("error", error);
          }
        
         
      }
    
    useEffect(()=>{   
      
      // eslint-disable-next-line
      },[news]);
    
  return (

    <div className='news_page_cont'>

        <div className='news_page_main'>

        <h2>{header}</h2> 
        {news?.length ===0 ? 
                <>
                <NoResult
                height={"fit-content"}
                />
                </>
            :
            <div  className='news_page_box'>
           
            
                <div className='news_page_grid'>
               
                
                    {news?.map((item,index)=>(

                
                        <div key={index} className='news_page_content'>
                          <div className='news_page_content_main'>

                         
                          <Tooltip
                            text={item.headline}
                             itemWidth="auto" 
                             transform="translateX(0)" 
                             top="-65px"
                          >
                           
                            <h4 onClick={()=>goToNews(item.id)}>
                            
                             
                            {item.headline.slice(0, 60)}{item.headline.length > 60 ? "..." : ""}
                            </h4>
                            </Tooltip>
                            <p> <p>{item.snippet.slice(0, 40)}{item.snippet.length > 40 ? "..." : ""}</p></p>

                            </div>

                            <div className='news_page_tags'>
                                <h4>{item.source}</h4>
                                {item.industries.slice(0,3).map((industry,index)=>(
                                    <h4
                                    key={index}
                                    >{industry.name}</h4>
                                    
                                ))}
                                                
                            </div>

                            <div className='news_page_day'>
                                <h4>{formatDate(item.date)}</h4>
                                <div>
                                    {item.is_bookmarked ?
                                    <img 
                                    onClick={()=>addBookmark(item.id)}
                                    src={bookmarked} alt="" />
                                    :
                                    <img 
                                    onClick={()=>addBookmark(item.id)}
                                    src={bookmark} alt="" />
                                    }
                                </div>
                            </div>
                        </div>

                    ))}
                </div>


            
                <div className='news_page_pagi'>
                    <Pagination
                        pageRange={5}
                        totalRecords={total}
                        recordsPerPage={records}
                        onPageChange={onPageChange} 
                        currentPage={currentPage}
                    />
                </div>
        
            </div>
        }
        </div>
            
    </div>
  )
}

export default NewsPage
