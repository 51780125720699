import React,{useState} from 'react';
import './PdfViewerContributor.scss';
import report from '../../../../assets/reportpdf.png';
import { GoDotFill } from "react-icons/go";
import { AiOutlineLink } from "react-icons/ai";
import { RxDownload } from "react-icons/rx";
import { FiBookmark } from "react-icons/fi";
import axios from 'axios';
import { useSelector } from 'react-redux';
import BaseButton from '../../../Onboarding/Reusables/BaseButton/BaseButton';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeIcon from '../../../../assets/greys.png';
import SummaryCard from '../Summary/SummaryCard';
import shakeImage from '../../../../assets/High-Five.png'
import { GoQuestion } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { IoWarningOutline } from "react-icons/io5";
// import CompletePage from '../../../Onboarding/Reusables/CompletePage/CompletePage';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 526,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
}

const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
   
  };
const PdfViewerContributor = ({docInfo,refresh}) => {


    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
    const viewOnlyUrl = `${docInfo.url}#toolbar=0&view=FitH`;
    const [open, setOpen] = useState(false); 
    const [openTwo, setOpenTwo] = useState(false); 
    const [withdrawReason,setWithdrawReason]= useState("");
    const [withdrawLoad,setWithdrawLoad]= useState(false);
    const [openThree,setOpenThree] = useState(false);
    const [reasonHeader,setReasonHeader] = useState('');
    const [reasonText,setReasonText] = useState('')
    const navigate = useNavigate()
    function formatDateLong(isoString) {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const withdrawDocument = () => {

        if(withdrawReason === ""){
            alert("Please enter a reason for withdrawing the document");
            return
        }

        try{ 
      
          setWithdrawLoad(true)

          let body = {
            "withdrawal_reason":withdrawReason
          }
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${docInfo.id}/withdraw`,body, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              setOpenThree(true)
              setOpenTwo(false)
              setWithdrawLoad(false)
              refresh()
             
            })
            .catch((error) => {
              setWithdrawLoad(false)
              if (error.response) {
                if (error.response.status === 401) {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
                } else {
                  alert(error.response.data.message);
                }
              } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
              } else {
                alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
                alert(error.response.data.message);
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
               alert("An error occurred. Please try again later.");
            }
          }
    };


    const resubmitDoc = () => {

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${docInfo.id}/resubmit`,{}, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              refresh()
             
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                      localStorage.clear(); // Clear local storage
                      window.location.href = '/login'; // Redirect to login page
                    } else {
                      alert(error.response.data.message);
                    }
                  } else if (error.request) {
                    alert("Check your connection. Unable to reach the server.");
                  } else {
                    alert("An error occurred. Please try again later.");
                  }
            });
          }
          catch(error){
            if (error.response) {
                alert(error.response.data.message);
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
               alert("An error occurred. Please try again later.");
            }
          }
    };

    
    function downloadPDF(){
        const link = document.createElement('a');
        link.href = docInfo.url;
        link.download = docInfo.title; // Optional: Set a default file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function handleRejectReason(){
        setReasonHeader("Rejection")
        setReasonText(docInfo.rejection_reason)
        setOpen(true)
    }
    
   
    function handleSusReason(){
        setReasonHeader("Suspension")
        setReasonText(docInfo.suspension_reason)
        setOpen(true)
    }

    return (

        <div>

        <div className='pdf-backgd'>

            <div className='pdf-main-cont'>

                <div className='pdf-cont-header'>

                    <div className='pdf-header-left'>

                        <div>
                         <img src={report} alt="" />
                        </div>

                        <div>
                            <h4>{docInfo.title}</h4>
                            <div className='pdf-left-tag'>
                                <div className='pdf-left-tag-views'>
                                    <GoDotFill
                                    color='#04802E'
                                    />
                                    <h3> {docInfo.total_views} Views</h3>
                                </div>
                               
                               
                                <p
                                    className='admin-col-status'
                                    style={{
                                    color:
                                        docInfo.status === "rejected"
                                        ? "#9E0A05"
                                        : docInfo.status === "pending_review"
                                        ? "#8B5904"
                                        : docInfo.status === "withdrawn"
                                        ? "#4B4F57"
                                        :
                                          docInfo.status === "suspended"
                                        ? "#9E0A05"
                                        : "#036B26",

                                        backgroundColor:
                                        docInfo.status === "rejected"
                                        ? "#FBEAE9"
                                        : docInfo.status === "pending_review"
                                        ? "#FEF0D8"
                                        : docInfo.status === "withdrawn"
                                        ? "#F3F2EF"
                                        :docInfo.status === "suspended"
                                        ? "#FBEAE9"
                                        : "#E7F6EC",
                                    fontWeight:500
                                    }}
                                    >
                                    {docInfo.status}
                                </p>
{/* 
                                <div className='pdf-left-tag-tags'>
                                    <GoDotFill
                                    color='#865503'
                                    />
                                    <h3>{docInfo.industries.map(industry => industry.name).join(', ')}</h3>
                                </div>
                     */}

                                <p>Submitted: {formatDateLong(docInfo.created_at)}</p>
                                {docInfo.merged_date !== null &&
                                <p>Merged: {formatDateLong(docInfo.merged_date)}</p>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='pdf-header-btn'>

                        <div >
                            <h4
                            onClick={()=>window.location.href=`/contributor/contributions/submitted`}
                            className='pdf-header-close-btn'
                            >Close </h4>
                        </div>

                        
                        {docInfo.status === "rejected" &&
                          <div 
                          onClick={()=>handleRejectReason()}
                          className='pdf-header-report-btn'>
                              <GoQuestion
                                  color='#D42620'
                              />
                              
                              <h4>Show reason for rejection</h4>
                          </div>
                        }
                        
                        {docInfo.status === "suspended" &&
                          <div 
                          onClick={()=>handleSusReason()}
                          className='pdf-header-report-btn'>
                              <GoQuestion
                                  color='#D42620'
                              />
                              
                              <h4>Show reason for suspension</h4>
                          </div>
                        }

                        {(docInfo.status === "pending_review" || 
                        docInfo.status === "merged" || docInfo.status === "rejected") &&
                        <div 
                        onClick={()=>setOpenTwo(true)}
                        className='pdf-header-report-btn'>
                              <IoWarningOutline
                                color='#D42620'
                            />
                            
                            <h4>Withdraw</h4>
                        </div>
                        }

                        {docInfo.status === "withdrawn" &&  
                        <BaseButton
                        onClick={()=>resubmitDoc()}
                            title='Resubmit Contribution'
                            height='44px'
                            width='auto'
                            padding="0em .5em"
                        />
                        }

                        <BaseButton
                        onClick={()=>navigate(`/contributor/editdoc/${docInfo.id}`)}
                            title='Update Metadata'
                            height='44px'
                            width='auto'
                            padding="0em .5em"
                        />
                        
                    </div>

                </div>


                <section>
                    <SummaryCard
                    docInfo={docInfo}
                    />
                </section>

                <section className='pdf-section' >

                   

                    <div style={{width:'90%'}} className='pdf-iframe-cont'>
                    
                    <iframe
                        src={viewOnlyUrl}
                        // onContextMenu={(e) => e.preventDefault()} // Disable right-click
                        title="PDF Viewer"
                        style={{width: '100%', height: '100vh', border: 'none',background:"#F6F6F6",padding:'40px' }}
                     >

                    </iframe>

                   
                    
                    </div>

                    <div>

                        <div className='pdf-icons'>
                            <AiOutlineLink
                            size={18}
                            />
                        </div>

                        <div 
                        onClick={()=>downloadPDF()}
                        className='pdf-icons'>
                            <RxDownload
                            
                            size={18}
                            />
                        </div>

                        <div className='pdf-icons'>
                            <FiBookmark
                            color={docInfo.is_bookmarked ? "#007EFF":""}
                            // onClick={()=>getBookmark()}
                            size={18}
                            />
                        </div>

                    </div>
                </section>

                

                    
            </div>
     
        </div>

        
       
        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

        <div className='pdf-reject-modal' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img onClick={() => setOpen(false)} src={closeIcon} alt="" />
                    </div>
                    <h4 >Reason for {reasonHeader}</h4>
                    <p>{reasonText}</p>
                </div>
                <div>
                    <BaseButton
                        title={"Close"}
                        width={"100%"}
                        height={"44px"}
                        padding={"0.5em 1em"}
                        onClick={() => setOpen(false)}
                    />
                </div>
            </div>
        </div>

        </Box>
        </Modal>



        {/* Withdraw modal */}
        <Modal
            open={openTwo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

        <div className='pdf-withdraw-modal'>
            
        <div className='pdf-withdraw-modal-top'>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setOpenTwo(false)} src={closeIcon} alt="" />
                </div>
                <h4>Withdraw Contribution</h4>

           
                <div className='pdf-withdraw-info'>

                    <h3>Title</h3>
                    <p>{docInfo.title}</p>
                    <h3>Type</h3>
                    <p>Document</p>
                    

                   
                </div>

                <textarea 
                    className='pdf-withdraw-text'
                    name="" id=""
                    placeholder='Please tell us why you are withdrawing this contribution'
                    value={withdrawReason}
                    onChange={(e) => setWithdrawReason(e.target.value)}
                >

                    </textarea>

                    <p>Withdrawing this contribution will make it unavailable for public viewing. Do you wish to continue?</p>
                    
            </div>


            {withdrawLoad ? 
            
            <p
                style={{textAlign:'center'}}
            >Please wait...</p>
            :
            <div>
                <BaseButton
                    title={"Confirm Withdrawal"}
                    width={"100%"}
                    height={"44px"}
                    padding={"0.5em 1em"}
                    onClick={() => withdrawDocument()}
                />
            </div>
            }
            
        </div>

        </Box>
        </Modal>


        <Modal
            open={openThree}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={styleTwo} >

        <div className='pdf-withdraw-modal'>
            
        <div className='pdf-withdraw-modal-top'>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setOpenThree(false)} src={closeIcon} alt="" />
                </div>
                <h4>Contribution Withdrawn</h4>

            <div style={{textAlign:"center",marginBottom:'24px'}}>
            <img src={shakeImage} alt="" />
            </div>
          
                
            <div>
                <BaseButton
                    title={"Close"}
                    width={"100%"}
                    height={"44px"}
                    padding={"0.5em 1em"}
                    onClick={() => setOpenThree(false)}
                />
            </div>
            
            
        </div>
        </div>

        </Box>
        </Modal>

        {/* <CompletePage
            imagePic={}
        /> */}

        </div>
    );
};

export default PdfViewerContributor;
