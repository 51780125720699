import React,{useState,useEffect,useRef} from 'react';
import ConsumerSidebar from '../Reusable/ConsumerSidebar/ConsumerSidebar';
import Navbar from '../Reusable/Navbar/Navbar';
import NewsPageHeader from '../Reusable/NewsPageHeader/NewsPageHeader';
import NewsPage from '../Reusable/NewsPage/NewsPage';
import NoResult from '../Reusable/NoResult/NoResult';
import DashboardLoader from '../Reusable/DashboardLoader/DashboardLoader';
import axios from 'axios';
import { useSelector } from "react-redux";
import ContributorSidebar from '../../ContributorDashoard/Reusable/ContributorSidebar/ContributorSidebar';
import { useLocation } from "react-router-dom";

const ConsumerNews = ({type}) => {
    const { pathname } = useLocation();
  const isFirstRender = useRef(true);
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [allNews, setAllNews] =  useState([])
  const [resultNews, setResultNews] =  useState([])
  const [allNewsTwo, setAllNewsTwo] =  useState([])
  const [allNewsThree, setAllNewsThree] =  useState([])
  const [totalOne, setTotalOne]= useState(null);
  const [totalTwo, setTotalTwo]= useState(null);
  const [totalThree, setTotalThree]= useState(null);
  const [totalFilter, setTotalFilter]= useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currPgSevdays, setCurrPgSev] = useState(1);
  const [currPgOld, setCurrPgOld] = useState(1);
  const [currPgRes, setCurrPgRes] = useState(1);
  
  const [result, setResult]= useState(false);

  
  const [filters, setFilters] = useState({
    language: null,
    search:null,
    keywords: [],
    startDate: null,
    endDate: null,
    sortType: null,
    sortOrder: null
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; 
    }
    
    filterNewsToDisplay(currPgRes);
    // eslint-disable-next-line
  }, [filters, currPgRes]);

  function convertDateFormat(dateString) {
  
    const [day, month, year] = dateString.split("/");
  
    return `${year}-${month}-${day}`;
  }
 
   
  const getNews = async () => {

    
    try {
        setLoading(true)
        const config = {
            headers: {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            
            }
        }
        const response = await axios.get(`${baseUrl}news`, config);
        if(response){
           setLoading(false)
          setResult(false)
        }
        
       
        }

        catch (error) {
          setLoading(false);
          console.log("error", error);
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.clear(); // Clear local storage
              window.location.href = '/login'; // Redirect to login page
            } else {
              alert(error.response.data.message);
            }
          } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
          } else {
            alert("An error occurred. Please try again later.");
          }
        }
  }
   
 
  const getNewsOne = async (page) => {

    const queryParams = {};
    if(page){
      queryParams.page = page
    }
    queryParams.limit= 9
      
    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
    
    try {
        // setLoading(true)
        const config = {
            headers: {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            
            }
        }
        const response = await axios.get(`${baseUrl}news/?${queryString}`, config);
        setResult(false)
        
        setAllNews(response.data.data)
        setTotalOne(response.data.meta.totalItems)
        }

        catch (error) {
          setLoading(false);
          console.log("error", error);
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.clear(); // Clear local storage
              window.location.href = '/login'; // Redirect to login page
            } else {
              alert(error.response.data.message);
            }
          } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
          } else {
            alert("An error occurred. Please try again later.");
          }
      }
  }

  const getNewsTwo = async (page) => {

    const queryParams = {};
  

    const endDate = new Date();
  
    // Get the date 7 days ago
    const startDate = new Date();
    endDate.setDate(startDate.getDate() - 7);

    if(page){
      queryParams.page = page
    }
   

    queryParams.end_date = startDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    queryParams.start_date = endDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    queryParams.limit = 9;

    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
    
    try {
       
        const config = {
            headers: {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            
            }
        }
        const response = await axios.get(`${baseUrl}news/?${queryString}`, config);
        setAllNewsTwo(response.data.data)
        setTotalTwo(response.data.meta.totalItems)
       
      
        }

        catch (error) {
          setLoading(false);
          console.log("error", error);
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.clear(); // Clear local storage
              window.location.href = '/login'; // Redirect to login page
            } else {
              alert(error.response.data.message);
            }
          } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
          } else {
            alert("An error occurred. Please try again later.");
          }
        
      }
  }


  const getNewsThree = async (page) => {

    const queryParams = {};
    const endDate = new Date();
  
    // Get the date 7 days ago
  
    endDate.setDate(endDate.getDate() - 8);

    queryParams.end_date = endDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    queryParams.limit = 9;
    if(page){
       queryParams.page = page;
    }
   

    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
    
    try {
       
        const config = {
            headers: {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            
            }
        }
        const response = await axios.get(`${baseUrl}news/?${queryString}`, config);
        console.log(response);
        setAllNewsThree(response.data.data);
        setTotalThree(response.data.meta.totalItems)
        }

        catch (error) {
          setLoading(false);
          console.log("error", error);
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.clear(); // Clear local storage
              window.location.href = '/login'; // Redirect to login page
            } else {
              alert(error.response.data.message);
            }
          } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
          } else {
            alert("An error occurred. Please try again later.");
          }
        
      }
  }
  

  const filterNewsToDisplay = async (page) => {
  
   
    const queryParams = {};
    
    if(page){
      queryParams.page = page;
    }
    if (filters.sortType) {
      queryParams.sort = filters.sortType;
    }
    if (filters.sortOrder) {
      queryParams.sort_order = filters.sortOrder;
    }
    
    if (filters.language) {
      queryParams['language[]'] = filters.language;
    }
    
    if (filters.search) {
      queryParams.search = filters.search;
    }
    if (filters.keywords.length > 0) {
      filters.keywords.forEach((item, index) => {
          queryParams[`industry[${index}]`] = item;
      });
  }
 
    if (filters.startDate) {
      queryParams.start_date = convertDateFormat(filters.startDate);
    }

    if (filters.endDate) {
      queryParams.end_date = convertDateFormat(filters.endDate);
    }

   
    
   
    queryParams.limit = 21;

    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');


    try {

      // setLoading(true)
        const config = {
            headers: {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            
            }
        }
        const response = await axios.get(`${baseUrl}news/?${queryString}`, config);
        console.log(response)
        // setLoading(false).
        // setCurrPgRes(1)
        setResult(true);
        setResultNews(response.data.data)
        setTotalFilter(response.data.meta.totalItems)

    }

    catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear(); // Clear local storage
          window.location.href = '/login'; // Redirect to login page
        } else {
          alert(error.response.data.message);
        }
      } else if (error.request) {
        alert("Check your connection. Unable to reach the server.");
      } else {
        alert("An error occurred. Please try again later.");
      }
    }
  }

  
  useEffect(()=>{   
    getNews()
  // eslint-disable-next-line
  },[]);

   useEffect(() => {
    getNewsOne(currentPage); 
    // eslint-disable-next-line
  }, [currentPage]); 

  useEffect(()=>{   
    getNewsTwo(currPgSevdays)
  // eslint-disable-next-line
  },[currPgSevdays]);

  useEffect(()=>{   
    getNewsThree(currPgOld)
  // eslint-disable-next-line
  },[currPgOld]);


  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    
  };
  const handlePageChangeSev = (page) => {
    setCurrPgSev(page);
  };
  const handlePageChangeOld = (page) => {
    setCurrPgOld(page);
  };
  const handlePageChangeRes = (page) => {
    setCurrPgRes(page);
   
  };


   const handleLanguageChange = (language) => {
    setFilters((prevFilters) => ({ ...prevFilters, language }));
   
    
  };

  const handleTagsChange = (keywords) => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords }));
   
  };
  
  const handleSortChange = (sortType, sortOrder) => { 
    setFilters((prevFilters) => ({ ...prevFilters, sortType, sortOrder }));
  }


  const handleDateChange = (startDate, endDate) => {

    setFilters((prevFilters) => ({ ...prevFilters, startDate, endDate }));
   
 
  };


  const handleLanguageRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, language: null }));
  
  };

  const handleDateRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate: null,endDate:null }));
  
  };
  const handleTagsRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords:[]}));
  
  };

  const handleSearchChange =(search)=>{
    setFilters((prevFilters) => ({ ...prevFilters, search }));
  }


  const resetFilter =() => {

      if(pathname.includes("/contributor/")){
          window.location="/contributor/news/all"
      }
      else if(pathname.includes("/consumer/")){
          window.location="/consumer/news/all"
      }
    
  }

 

  const reloadMain=()=>{
  
    getNewsOne(currentPage)
    getNewsTwo(currPgSevdays)
    getNewsThree(currPgOld)
  }

  const reloadFilter=()=>{

   filterNewsToDisplay(currPgRes)

  }

  const getBookmarks = ()=>{
    if(pathname.includes("/contributor/")){
      window.location="/contributor/news/all/bookmarks"
    }
    else  if(pathname.includes("/consumer/")){
          window.location="/consumer/news/all/bookmarks"
    }

  }

  
  
  

  return (
    <>
   

    <div className='dashboard-main'>
        <div>
          {type === "contri" ?
            <ContributorSidebar/>
          :
           <ConsumerSidebar/>
          }
       
        </div>
      
      <section>
        <Navbar/>
     
        {loading ? (

        <div>
        <DashboardLoader/>
        </div>
        ):(
        <>
           <NewsPageHeader
              onLanguageChange={handleLanguageChange}
              onSearchChange={handleSearchChange}
              onTagsChange={handleTagsChange}
              onDateChange={handleDateChange}
              onSortChange={handleSortChange}
              onReset={resetFilter}
              onLangRemove={handleLanguageRemove}
              onDateRemove={handleDateRemove}
              onTagsRemove={handleTagsRemove}
              newsHeader={"All News"}
              goToBookMark={getBookmarks}
              searchName={"news"}
              />

        {result ? (
          <>
            {resultNews.length === 0 ?(

              <>
           
              <NoResult
                height="739px"
              /> 
              </>
            ):(
            <>
             

              <NewsPage
              news={resultNews}
              header={"Results"}
              total={totalFilter}
              reloadPage={reloadFilter}
              currentPage={currPgRes}
              onPageChange={handlePageChangeRes} 
              records={21}
            />
              </>
            )
        
            }
          </>

        ):(
          <>
              
                
              <NewsPage
                news={allNews}
                header={"For you"}
                total={totalOne}
                onPageChange={handlePageChange} 
                records={9}
                currentPage={currentPage}
                reloadPage={reloadMain}
              />

              <NewsPage
                news={allNewsTwo}
                header={"Last 7 days"}
                total={totalTwo}
                onPageChange={handlePageChangeSev} 
                reloadPage={reloadMain}
                currentPage={currPgSevdays}
                records={9}
              />

              <NewsPage
                news={allNewsThree}
                header={"Older News"}
                total={totalThree}
                onPageChange={handlePageChangeOld} 
                reloadPage={reloadMain}
                currentPage={currPgOld}
                records={9}
              />
            </>
        )}
        </>
        )}
       
      </section>
    </div>
    
    </>
  )
}

export default ConsumerNews
