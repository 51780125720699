import React from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import img1 from '../../../../assets/reportIcon2.svg';
import  './SimilarResources.scss'
const SimilarResources = ({header,similar}) => {

   
    const getRandomItems = (array, num) => {
        const shuffled = array.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, num);
    };
    
    const randomSimilarItems = getRandomItems(similar, 5);

    const truncateTitle = (title, maxLength) => {
      if (title.length > maxLength) {
        return title.substring(0, maxLength) + '...';
      }
      return title;
    };

  return (
    
    <div className='similar-resource-main'>
      <div className='similar-resource-body' >

        <div className='similar-resource-header'>
            <h4>{header}</h4>
            <div>
                <h4>See all</h4>
                <IoIosArrowRoundForward
                size={30}
                color=''
                />
            </div>
        </div>
        {randomSimilarItems.map((item,index)=>(
        <div key={index} className='similar-resource-content'>
           
                
            <div  className='similar-resource-img'>
                <div>
                     <img src={img1} alt="" />
                </div>
                
                 <div
                 onClick={()=>window.location.href=`/consumer/documents/home/viewdocument/${item.id}`}
                 >
                     <h4>{truncateTitle(item.title, 70)}</h4>
                 </div>
            </div>

            <div>
            <li>
              {item.industries.length > 0 ? (
                <>
                  {item.industries[0].name}
                  {item.industries.length > 1 && ` + ${item.industries.length - 1} more`}
                </>
              ) : (
                "No industries"
              )}
            </li>            
            </div>

            <div>
                <p>{item.publication_year}</p>
            </div>
 
                <IoIosArrowForward
                size={20}
                color='#9CA0A5'
                />
                
        </div>
        ))}

      </div>
    </div>
  
  )
}

export default SimilarResources
