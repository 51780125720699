import React,{useEffect,useState} from 'react'
import ConsumerSidebar from '../../Reusable/ConsumerSidebar/ConsumerSidebar'
import Navbar from '../../Reusable/Navbar/Navbar'
import PageHeader from '../../Reusable/PageHeader/PageHeader'
import { useParams,useLocation } from 'react-router-dom';
import ReportContentView from '../../Reusable/ReportContentView/ReportContentView';
import SimilarResources from '../../Reusable/SimilarResources/SimilarResources';
import ContributorSidebar from '../../../ContributorDashoard/Reusable/ContributorSidebar/ContributorSidebar';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardLoader from '../../Reusable/DashboardLoader/DashboardLoader';

const ReportsPage = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const path = useLocation().pathname;
  const paramsVal = useParams();
  const [docDetails,setDocDetails] = useState([])
  const [similarResources,setSimilarResources] = useState([])
  const [loading,setLoading] = useState(true)

  const getDocuments = () => {

  try{ 

 
    const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Authorization header
          },
      };
  
    axios.get(`${baseUrl}documents/consumer/${paramsVal.reportid}`, config)
      .then((response) => {
        setLoading(false)
        console.log(response.data);
        setDocDetails(response.data.data)
        
        // setTotalItems(response.data.meta.totalItems)
        
      
      })
      .catch((error) => {
        
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear(); // Clear local storage
            window.location.href = '/login'; // Redirect to login page
          } else {
            alert(error.response.data.message);
          }
        } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
        } else {
          alert("An error occurred. Please try again later.");
        }
      });
    }
    catch(error){
      if (error.response) {
          alert(error.response.data.message);
      } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
      } else {
         alert("An error occurred. Please try again later.");
      }
    }
  };


  const getSimilar = () => {

  try{ 

 
    const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Authorization header
          },
      };
  
    axios.get(`${baseUrl}documents/consumer/${paramsVal.reportid}/similar-resource`, config)
      .then((response) => {
        setLoading(false)
        console.log(response.data);
        setSimilarResources(response.data.data)
        
        // setTotalItems(response.data.meta.totalItems)
        
      
      })
      .catch((error) => {
        
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear(); // Clear local storage
            window.location.href = '/login'; // Redirect to login page
          } else {
            alert(error.response.data.message);
          }
        } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
        } else {
          alert("An error occurred. Please try again later.");
        }
        window.history.back();
      });
    }
    catch(error){
      if (error.response) {
          alert(error.response.data.message);
      } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
      } else {
         alert("An error occurred. Please try again later.");
      }
    }
  };

  
     useEffect(()=>{   
          getDocuments()
          getSimilar()
        // eslint-disable-next-line
        },[]);
  


  return (
    <div className='dashboard-main'>
    
    {path.startsWith("/contributor/contributions/") && <ContributorSidebar/>}
    
    {path.startsWith("/consumer/documents/home") && <ConsumerSidebar/>}
    <section>
      <Navbar/>

      {loading ? 

      <DashboardLoader/>
      :
       <>
        <PageHeader
        reportTitle={docDetails.title}
        pageName={"Documents"}
      
        />
        <ReportContentView
        docDetails={docDetails}
        
        />

        <SimilarResources
        header={"Similar Resources"}
        similar={similarResources}
        />
      </>
      }
    </section>
      
  </div>
  )
}

export default ReportsPage
