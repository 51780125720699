import React,{useEffect, useState,useRef} from 'react'
import ConsumerSidebar from '../Reusable/ConsumerSidebar/ConsumerSidebar'
import Navbar from '../Reusable/Navbar/Navbar'
import NewsPageHeader from '../Reusable/NewsPageHeader/NewsPageHeader'
import ReportsCard from './ReportsCard/ReportsCard'
import PaginationReport from '../Reusable/Pagination/ReportPagination'
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardLoader from '../Reusable/DashboardLoader/DashboardLoader'
import NoResult from '../Reusable/NoResult/NoResult'




const ConsumerReport = () => {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [loading, setLoading] = useState(false);
  const isFirstRender = useRef(true);
  const [documentsData, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  
  const [filters, setFilters] = useState({
      language: null,
      search:null,
      keywords: [],
      startDate: null,
      endDate: null,
      sortType: null,
      sortOrder: null
    });

 
    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        setLoading(true)
        return; 
      }
      
      getDocuments(currentPage);
      // eslint-disable-next-line
    }, [filters, currentPage]);

  function convertDateFormat(dateString) {
  
    const [day, month, year] = dateString.split("/");
  
    return `${year}-${month}-${day}`;
  }


  const getDocuments = (page = 1) => {

    const queryParams = {
      page,
      limit: itemsPerPage,
    };
    
    if(page){
      queryParams.page = page;
    }
    
    if (filters.language) {
      queryParams['language[]'] = filters.language;
    }
    
    if (filters.search) {
      queryParams.search = filters.search;
    }
    if (filters.keywords.length > 0) {
      filters.keywords.forEach((item, index) => {
          queryParams[`industry[${index}]`] = item;
      });
    }
    
    if (filters.startDate) {
      queryParams.start_date = convertDateFormat(filters.startDate);
    }
    if (filters.sortType) {
      queryParams.sort = filters.sortType;
    }
    if (filters.sortOrder) {
      queryParams.sort_order = filters.sortOrder;
    }
    

    if (filters.endDate) {
      queryParams.end_date = convertDateFormat(filters.endDate);
    }

    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');

  try{ 

 
    const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Authorization header
          },
      };
  
    axios.get(`${baseUrl}documents/consumer?${queryString}`, config)
      .then((response) => {
        setLoading(false)
        console.log(response.data);
        setDocuments(response.data.data)
        
        setTotalItems(response.data.meta.totalItems)
        
      
      })
      .catch((error) => {
     
        if (error.response) {
          console.error("Error response:", error.response.data);
          alert(error.response.data.message);
        } else if (error.request) {
          console.error("Error request:", error.request);
          alert("Network error: Please check your connection.");
        } else {
          console.error("Error:", error.message);
          alert("An error occurred. Please try again later.");
        }
      });
    }
    catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear(); // Clear local storage
          window.location.href = '/login'; // Redirect to login page
        } else {
          alert(error.response.data.message);
        }
      } else if (error.request) {
        alert("Check your connection. Unable to reach the server.");
      } else {
        alert("An error occurred. Please try again later.");
      }
    }
  };

  
  const handleLanguageChange = (language) => {
    setFilters((prevFilters) => ({ ...prevFilters, language }));
   
    
  };

  const handleTagsChange = (keywords) => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords }));
   

  };


  const handleDateChange = (startDate, endDate) => {

    setFilters((prevFilters) => ({ ...prevFilters, startDate, endDate }));
   
 
  };


  const handleLanguageRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, language: null }));
  
  };

  const handleDateRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate: null,endDate:null }));
  
  };

  const handleSortChange = (sortType, sortOrder) => { 
    setFilters((prevFilters) => ({ ...prevFilters, sortType, sortOrder }));
  };

  const handleTagsRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords:[]}));
  
  };

  const handleSearchChange =(search)=>{
    setFilters((prevFilters) => ({ ...prevFilters, search }));
  }


  const resetFilter=()=>{
    window.location="/consumer/documents/home"
  }

  const getBookmarks = ()=>{
      window.location.href="/consumer/documents/home/bookmarks"
  }


  // const handlePageChange = (page) => {
  //   setCurrPg(page);
   
  // };

    function reloadPage(){
      getDocuments(currentPage)
    }
   useEffect(() => {
    getDocuments(currentPage);
    // eslint-disable-next-line
  }, [currentPage, itemsPerPage, filters]);


  return (

    <>
    

    <div className='dashboard-main'>
      <ConsumerSidebar/>
      <section>
        <Navbar/>
        {loading ? (

          <div>
          <DashboardLoader/>
          </div>
          ):(


            <>
             <NewsPageHeader
          newsHeader={"Document"} 
          searchName={"report"}
          onLanguageChange={handleLanguageChange}
          onSearchChange={handleSearchChange}
          onTagsChange={handleTagsChange}
          onDateChange={handleDateChange}
          onReset={resetFilter}
          onSortChange={handleSortChange}
          onLangRemove={handleLanguageRemove}
          onDateRemove={handleDateRemove}
          onTagsRemove={handleTagsRemove}
          goToBookMark={getBookmarks}
        />
        {documentsData.length !==0 ? 
        <>
       
        <ReportsCard
          apiDocuments = {documentsData}
          reloadPage={reloadPage}
        />
        <PaginationReport 
         currentPage={currentPage}
         itemsPerPage={itemsPerPage}
         totalItems={totalItems}
         onPageChange={setCurrentPage}
         onItemsPerPageChange={setItemsPerPage}
        />

        </>
        :
        <>
       
        <NoResult
          height="739px"
        /> 
        </>
        }
        </>
        )}
      </section>
    </div>
    
    </>
  )
}

export default ConsumerReport
