import React,{useContext,useState} from 'react';
import './CreateDocument.scss';
import arrow from '../../../assets/backicon.svg'
import { multiStepContext } from '../../Onboarding/Reusables/ProgressBar/StepContext';
import DocAcknowledge from './DocAcknowledge/DocAcknowledge';
import CreateDocSideBar from './CreateDocSidebar/CreateDocSideBar';
import DocumentSummary from './DocumentSummary/DocumentSummary';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import axios from 'axios';
import BaseButton from '../../Onboarding/Reusables/BaseButton/BaseButton';
import ReportCard from '../../ConsumerDashboard/Reusable/ReportCard/ReportCard';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height:626,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  outline: "none",
  padding:"16px 32px 32px",
  
}

const CreateDocument = () => {
  const { currentstep,setStep } = useContext(multiStepContext);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [open, setOpen] = useState(false); 
  const [file, setFile] = useState(null);
  const [selectedKeyId, setSelectedKeyId] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [doctype, setDocType] =  useState('') ;
  const [howDoc, setHowDoc] =  useState('');
  const [docSource, setDocSource] = useState('');
  const [docTitle,setDocTitle] = useState('');
  const [description,setDescription]=useState('');
  const [author,setAuthor]= useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedLang, setSelectedLang]= useState ('')
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [docId, setDocId] = useState(null);
  const navigate = useNavigate();


  function handleDocData (docData) {
    console.log(docData)
    setDocSource(docData.docSource)
    setAuthor(docData.author)
    setFile(docData.file)
    setDescription(docData.description)
    setSelectedDate(docData.selectedDate)
    setDocTitle(docData.docTitle)
    setSelectedKeyId(docData.selectedKeyId)
    setHowDoc(docData.howDoc)
    setDocType(docData.doctype)
    setSelectedLang(docData.selectedLang)
    setSelectedLocation(docData.selectedLocation)
  }


  console.log("Selected Location:", selectedLocation); // Log the selected values
  
  // function dateFormat(dateStr) {
  //   // Split the input string by the delimiter "-"
  //   const [day, month, year] = dateStr.split("/");
  
  //   // Return the date in the desired format
  //   return `${year}-${month}-${day}`;

  // }

  const createDocument = () => {

        setLoading(true)
        const formData = new FormData();
        formData.append("file", file); 
        formData.append("title", docTitle);
        formData.append("description", description);
        formData.append("author", author);
        formData.append("source", docSource);
        formData.append("access", howDoc);
        formData.append("document_type", doctype);
        formData.append("language", selectedLang); 
        selectedKeyId.forEach((item) => {formData.append('industry', item)}) 
        selectedLocation.countries.forEach((item) => {formData.append('location', item)}) 
        if (selectedLocation.regions.length < 5 || selectedLocation.countries.length > 1) {
          formData.append('region', 'multiple');
        } else if (selectedLocation.regions.length === 0 && selectedLocation.countries.length === 1) {
            formData.append('region', selectedLocation.countries[0]);
        } else if (selectedLocation.regions.length === 5) { // Assuming 5 represents all regions selected
            formData.append('region', 'Africa');
        } else {
            selectedLocation.regions.forEach((item) => {
                formData.append('region', item);
            });
        }
        

        formData.append("publication_year", selectedDate); 
  
            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`, // Authorization header
              },
          };
          if(isUpdate){
        axios.patch(`${baseUrl}documents/contributor/${docId}`, formData, config)
          .then((response) => {
            
            setOpen(true)
            setLoading(false)
            
          })
          .catch((error) => {
            setLoading(false)
            .catch((error) => {
              setLoading(false);
              if (error.response) {
                if (error.response.status === 401) {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
                } else {
                  alert(error.response.data.message);
                }
              } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
              } else {
                alert("An error occurred. Please try again later.");
              }
            });
          });
        }
        else{
        axios.post(`${baseUrl}documents`, formData, config)
          .then((response) => {
          
            setOpen(true)
            setLoading(false)
            
          })
          .catch((error) => {
            setLoading(false)
            if (error.response) {
              if (error.response.status === 401) {
                localStorage.clear(); // Clear local storage
                window.location.href = '/login'; // Redirect to login page
              } else {
                alert(error.response.data.message);
              }
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
        }
  };

  const handleUpdateChange = (value, id) => {
    setIsUpdate(value);
    setDocId(id);
  };
  const handleGoback = () => {
    if (isUpdate) {
      navigate(`/user/viewdocument/pdf/contributor/${docId}`);
    } else {
      navigate("/contributor/dashboard");
    }
  };


  return (
    <div>

        <div className='create-doc-banner'>
           { currentstep === 1 && 
            <img 
            onClick={()=>handleGoback()}
            src={arrow} alt="" />
           }
           { currentstep === 2 && 
            <img 
            onClick={()=> setStep(1)}
            src={arrow} alt="" />
           }
        </div>

        <div className='create-doc-body'>
          {currentstep === 1 && 
          <DocumentSummary
            onSelect={handleDocData}
            onUpdateChange={handleUpdateChange}
          />}
          {currentstep === 2 && 
          <DocAcknowledge
            callSummaryFunction={createDocument}
            loadingFunction={loading}
          />}
         
          <CreateDocSideBar
          
          />
        </div>



        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

        <div className='pdf-reject-modal' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img onClick={() => setOpen(false)} src={closeIcon} alt="" />
                    </div> */}
                    <h4 style={{margin:10}}>Done!</h4>
                    <p style={{textAlign:'center'}}> Your contribution has been submitted and is under review.</p>
                    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'1em' }}>
                    <ReportCard
                         contHeight={"360px"}
                         contWidth={"254px"}
                         imgBoxWidth={"100px"}
                         contPadding={"1em"}
                         transformLine={"rotate(40deg)"}
                         lineHeight={"1.6cm"}
                         lineRight={"-5px"}
                         lineTop={"0px"}
                         lineWidth={"20px"}
                         transformOrigin={"top right"}
                         headerFont={"1rem"}
                         lineBottom={"-20px"}
                         lineLeft={"25px"}
                         textMarginTop={"10px"}
                         docText={docTitle}
                         docYear={selectedDate}
                         yearFont={"0.8rem"}
                />
                    </div>
                    
                </div>

                

                <div>
                    <BaseButton
                        title={"Close"}
                        width={"100%"}
                        height={"44px"}
                        padding={"0.5em 1em"}
                        onClick={() =>  window.location = '/contributor/contributions/submitted'}
                    />
                </div>
            </div>
        </div>

        </Box>
        </Modal>

    </div>
  )
}

export default CreateDocument
