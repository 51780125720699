import React,{useEffect, useState} from 'react'
import PdfViewer from '../../Reusable/PdfViewer/PdfViewer'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DashboardLoader from '../../Reusable/DashboardLoader/DashboardLoader';




const ViewReport = () => {

  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const paramsVal = useParams();
  const [docDetails,setDocDetails]=useState([]);
  const [pdfUrl,setPdfUrl]= useState("")
  const [loading,setLoading]=useState(true)

  const getDocPdf = () => {
    try { 
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            responseType: 'arraybuffer' // Ensure binary data is received
        };
    
        axios.get(`${baseUrl}documents/consumer/${paramsVal.reportid}/view`, config)
        .then((response) => {
            console.log(response);

            // Convert the response to a Blob
            const blob = new Blob([response.data], { type: 'application/pdf' });
            
            const url = URL.createObjectURL(blob); // Create a Blob URL from the blob
            const viewOnlyUrl = `${url}#toolbar=0&view=FitH`; // Append parameters for view-only mode
            setPdfUrl(viewOnlyUrl); // Set the URL in the state
            

            setLoading(false); // Stop loading spinner or indication
        })
        .catch((error) => {
          console.error('Error fetching PDF:', error);
            setLoading(false); // Stop loading spinner in case of error
          if (error.response) {
            console.error("Error response:", error.response.data);
            alert(error.response.data.message);
          } else if (error.request) {
            console.error("Error request:", error.request);
            alert("Network error: Please check your connection.");
          } else {
            console.error("Error:", error.message);
            alert("An error occurred. Please try again later.");
          }
        });
      }
      catch (error) {
        setLoading(false);
        console.log("error", error);
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear(); // Clear local storage
            window.location.href = '/login'; // Redirect to login page
          } else {
            alert(error.response.data.message);
          }
        } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
        } else {
          alert("An error occurred. Please try again later.");
        }
      }
    };


    const getDocuments = () => {

      try{ 
    
     
        const config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Authorization header
              },
          };
      
        axios.get(`${baseUrl}documents/consumer/${paramsVal.reportid}`, config)
          .then((response) => {
            
            console.log(response.data);
            setDocDetails(response.data.data)
                    
          })
          .catch((error) => {
            
            if (error.response) {
              if (error.response.status === 401) {
                localStorage.clear(); // Clear local storage
                window.location.href = '/login'; // Redirect to login page
              } else {
                alert(error.response.data.message);
              }
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
        }
        catch(error){
          if (error.response) {
              alert(error.response.data.message);
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
             alert("An error occurred. Please try again later.");
          }
        }
    };
    
    // const reportDocume


  
    useEffect(()=>{   
      getDocPdf();
      getDocuments()
    // eslint-disable-next-line
    },[]);
  
  return (
    <div>
      {loading ?
      <DashboardLoader/>
      :
      <div>
      <PdfViewer
      pdfUrl={pdfUrl}
      docInfo={docDetails}
      refreshDoc={getDocuments}
      />
      
     
        </div>

      }
    </div>
  )
}

export default ViewReport
