import React,{useEffect,useState} from 'react'
import Navbar from '../../../ConsumerDashboard/Reusable/Navbar/Navbar'
import ContributorSidebar from '../../Reusable/ContributorSidebar/ContributorSidebar'
import ContributionHeader from '../../Reusable/ContributionHeader/ContributionHeader'
import ResourceTable from '../../Reusable/ResourceTable/ResourceTable'
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardLoader from '../../../ConsumerDashboard/Reusable/DashboardLoader/DashboardLoader'


const SubmittedContributions = () => {
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [docData,setDocData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [filters, setFilters] = useState({
    
    language: null,
    search:null,
    keywords: [],
    startDate: null,
    endDate: null,
    sortType: null,
    sortOrder: null
    
    });


  function convertDateFormat(dateString) {

    const [day, month, year] = dateString.split("/");
  
    return `${year}-${month}-${day}`;
  }

  const getDocuments = () => {
  
  const queryParams = {};
    
  queryParams.contributor_id = userData.id;

  if (filters.search) {
    queryParams.search = filters.search;
  }

  // if(page){
  //   queryParams.page = page;
  // }

  if (filters.language) {
    queryParams['language[]'] = filters.language;
  }
  if (filters.sortType) {
    queryParams.sort = filters.sortType;
  }
  if (filters.sortOrder) {
    queryParams.sort_order = filters.sortOrder;
  }
 
  if (filters.keywords.length > 0) {
    filters.keywords.forEach((item, index) => {
        queryParams[`industry[${index}]`] = item;
    });
}
  
  if (filters.startDate) {
    queryParams.start_date = convertDateFormat(filters.startDate);
  }

  if (filters.endDate) {
    queryParams.end_date = convertDateFormat(filters.endDate);
  }

 
  const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');

  try{ 

    
    const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Authorization header
          },
      };
  
    axios.get(`${baseUrl}documents/contributor?${queryString}`, config)
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setDocData(response.data.data);
        
      
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear(); // Clear local storage
            window.location.href = '/login'; // Redirect to login page
          } else {
            alert(error.response.data.message);
          }
        } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
        } else {
          alert("An error occurred. Please try again later.");
        }
      });
    }
    catch(error){
      if (error.response) {
          alert(error.response.data.message);
      } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
      } else {
         alert("An error occurred. Please try again later.");
      }
    }
  };

   useEffect(()=>{   
        getDocuments()
      // eslint-disable-next-line
      },[filters]);

      const handleSearchChange =(search)=>{
        setFilters((prevFilters) => ({ ...prevFilters, search }));
      }

      
   const handleLanguageChange = (language) => {
    setFilters((prevFilters) => ({ ...prevFilters, language }));
   
    
  };

  const handleTagsChange = (keywords) => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords }));
   

  };

  const handleSortChange = (sortType, sortOrder) => { 
    setFilters((prevFilters) => ({ ...prevFilters, sortType, sortOrder }));
  }

  const handleDateChange = (startDate, endDate) => {

    setFilters((prevFilters) => ({ ...prevFilters, startDate, endDate }));
   
  };



  const handleLanguageRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, language: null }));
  
  };

  const handleDateRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate: null,endDate:null }));
  
  };
  const handleTagsRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords:[]}));
  
  };

  return (
    <div className='dashboard-main'>

        <div>
         <ContributorSidebar/>
        </div>

        <section>
            <Navbar/>
            <ContributionHeader
                onSearchChange={handleSearchChange}
                onLanguageChange={handleLanguageChange}
                onTagsChange={handleTagsChange}
                onDateChange={handleDateChange}
                onSortChange={handleSortChange}
                onTagsRemove={handleTagsRemove}
                onLangRemove={handleLanguageRemove}
                onDateRemove={handleDateRemove}
            />
            {loading ?
            <DashboardLoader/>
            :
            <>
            {docData.length === 0 ?
            <h2 style={{textAlign:'center',marginTop:'50px'}}>No contributions submitted yet</h2> :
            <ResourceTable
            tableData={docData}
            />}
            </>
            }
        </section>
     </div>
  )
}

export default SubmittedContributions
