import React from "react";
import styles from "./SummaryCard.module.css";

const SummaryCard = ({docInfo}) => {
  return (
    <div className={styles.card}>

      <h3 className={styles.title}>Summary</h3>

      <div className={styles.sum_content}>

      <div className={styles.row}>
        <strong>Title</strong>
        <span>{docInfo.title}</span>
      </div>

      <div className={styles.row}>
        <strong>Description</strong>
        <p>
          {docInfo.description}
        </p>
      </div>

      <div className={styles.row}>
        <strong>Tags</strong>
        <div className={styles.tags}>
        {docInfo.industries.slice(0, 5).map((info, index) => (
            <span key={index} className={styles.tag}>{info.name}</span>
        ))}
        {docInfo.industries.length > 5 && (
            <span className={styles.tag}>+ {docInfo.length - 5} more</span>
        )}
     </div>
      </div>

      <div className={styles.row}>
        <strong>Author</strong>
        <span>{docInfo.author}</span>
      </div>

      <div className={styles.row}>
        <strong>Publication Year</strong>
        <span>{docInfo.publication_year}</span>
      </div>

      <div className={styles.row}>
        <strong>Document Type</strong>
        <span>{docInfo.document_type}</span>
      </div>

      <div className={styles.row}>
        <strong>Coverage</strong>
        <span>{docInfo.region}</span>
      </div>

      <div className={styles.row}>
        <strong>Source</strong>
        <span>{docInfo.source}</span>
      </div>

      </div>
    </div>
  );
};

export default SummaryCard;
