import React,{useState} from 'react';
import { IoMdArrowBack } from "react-icons/io";
import { IoMdArrowForward } from "react-icons/io";
import jbk from '../../../../assets/jbk.png';
import report2 from '../../../../assets/reportIcon2.svg';
import './JumpBackIn.scss';
import ReportCard from '../../Reusable/ReportCard/ReportCard';


const JumpBackIn = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (currentIndex < Math.ceil(jump.length / 3) - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

const jump =[
    {
        para:"African Oil and Gas Supply Chain and Opportunities in the Energy Transistion",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply Chain and Opportunities in the Energy ",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply Chain and Opportunities in the",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply Chain and Opportunities ",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply Chain and ",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and ",
        img1:jbk,
        img2:report2
    },
    {
        para:"Anigeriam erigbnoekn nreoneognog O",
        img1:jbk,
        img2:report2
    },
    {
        para:"Report finds more than 200 projects could benefit from  Opportunities in ",
        img1:jbk,
        img2:report2
    },
    {
        para:"Report finds more than 200 projects could benefit from subsea boosting",
        img1:jbk,
        img2:report2
    },
    {
        para:"Report finds more than 200 projects could benefit",
        img1:jbk,
        img2:report2
    }
]
  return (
    <div className='jump-back-main'>

   
    <div className='jump-back-cont'>

        <div className='jump-back-header'>
            <h4>Jump Back In</h4>
            <div className='jump-back-arrow'>
            <IoMdArrowBack 
            color={currentIndex === 0 ? "#A29999" : "#000000"}
            size={20} onClick={handlePrev} />

            <IoMdArrowForward 
             color={currentIndex === Math.ceil(jump.length /3) - 1 ? "#A29999" : "#000000"}
            size={20} 
            onClick={handleNext} 
            />
            
            </div>
        </div>
     
        <div className='jump-back-two'>
        {jump.map((item,index)=>(

       
            <div 
            className={`jump-back-two-box ${index < currentIndex * 3 || index >= (currentIndex + 1) * 3 ? 'hidden' : ''}`} key={index}
            >
                <div className='jump-back-two-img'>
                    {/* <img src={item.img1} alt="" /> */}
                    <ReportCard
                         contHeight={"100px"}
                         contWidth={"80px"}
                         imgBoxWidth={"34px"}
                         contPadding={"0em 0.3em"}
                         transformLine={"rotate(137deg)"}
                         lineHeight={"0.2cm"}
                         lineRight={"13px"}
                         lineTop={"13px"}
                         lineWidth={"20px"}
                         transformOrigin={"top right"}
                         headerFont={"0.3rem"}
                         lineBottom={"-10px"}
                         lineLeft={"-17px"}
                         textMarginTop={"0px"}
                         docText={"African Oil and Gas Supply Chain and Opportunities in the Energy Transistion"}
                         docYear={"2021"}
                         yearFont={"0.3rem"}
                    />
                </div>

                <div className='jump-back-two-content'>
                    <p>{item.para}</p>
                    <div>
                        <img src={report2} alt="imgI" />
                        <p>REPORT</p>
                    </div>
                </div>
            </div>
        ))}
        </div>

    </div>
    </div>
  )
}

export default JumpBackIn
