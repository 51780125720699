import React from 'react'
import './ResourceTable.scss';
import doc from '../../../../assets/contributortablereport.png'
import { GoDotFill } from "react-icons/go";



const ResourceTable = ({tableData}) => {

  
    
    function formatDate(dateString) {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return date.toLocaleDateString('en-US', options); 
    }
      return (

        <div className='admin-resc-table-cont'>

        
            <table  style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead className='admin-resc-head'>
                <tr>
                <th></th>
                <th>Title</th>
               
                <th>Date Created</th>
                <th>Tags</th>
                <th>Status</th>
                <th></th>
                </tr>
            </thead>

            <tbody className='admin-resc-body' >
                {tableData.map((row, index) => (
                <tr key={index}>

                    <td>
                    <img src={doc} alt="" />
                    </td>

                    <td 
                      style={{cursor:'pointer'}}
                    >
                       
                           
                            <p
                            onClick={()=>window.location.href=`/user/viewdocument/pdf/contributor/${row.id}`}
                            >{row.title.slice(0,60)}{row.title.length > 60 ? "..." : ""}</p>
                        
                        
                    </td>

                   

                    <td >  
                            <p>{formatDate(row.created_at)}</p>   
                    </td>

                    <td style={{maxWidth:'100px'}}>
                        <div className='admin-col-two'>
                            <GoDotFill/>
                            <p>
                            {row.industries[0]?.name}
                            {row.industries.length > 1 && ` + ${row.industries.length - 1}`}
                            </p>
                        </div>
                        
                    
                    </td>

                    <td 
                    
                    >
                    <p
                        className='admin-col-status'
                        style={{
                        color:
                            row.status === "rejected"
                            ? "#9E0A05"
                            : row.status === "pending"
                            ? "#8B5904"
                            : row.status === "withdrawn"
                            ? "#4B4F57"
                            :
                             row.status === "suspended"
                            ? "#9E0A05"
                            : "#036B26",

                         backgroundColor:
                         row.status === "rejected"
                         ? "#FBEAE9"
                         : row.status === "pending"
                         ? "#FEF0D8"
                         : row.status === "withdrawn"
                         ? "#F3F2EF"
                         :   row.status === "suspended"
                         ? "#FBEAE9"
                         : "#E7F6EC",

                       

                        }}
                    >
                        {row.status}</p>
                    
                    </td>
                    <td 
                    className='table-btn-view'
                    >
                        <button
                         onClick={()=>window.location.href=`/user/viewdocument/pdf/contributor/${row.id}`}
                        >
                          View
                        </button>
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>
      );
}

export default ResourceTable
