import React,{useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PdfViewerContributor from '../Reusable/PdfViewerContributor/PdfViewerContributor';
import DashboardLoader from '../../ConsumerDashboard/Reusable/DashboardLoader/DashboardLoader';

const ViewReportCont = () => {

  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const paramsVal = useParams();
  const [docDetails,setDocDetails]=useState([]);

  const [loading,setLoading]=useState(true)

  


    const getDocuments = () => {

      try{ 
    
     
        const config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Authorization header
              },
          };
      
        axios.get(`${baseUrl}documents/contributor/${paramsVal.reportid}`, config)
          .then((response) => {
            setLoading(false)
            console.log(response.data);
            setDocDetails(response.data.data)
                    
          })
          .catch((error) => {
            setLoading(false)
            if (error.response) {
              if (error.response.status === 401) {
                localStorage.clear(); // Clear local storage
                window.location.href = '/login'; // Redirect to login page
              } else {
                alert(error.response.data.message);
              }
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
        }
        catch(error){
          if (error.response) {
              alert(error.response.data.message);
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
             alert("An error occurred. Please try again later.");
          }
        }
    };
    
  
    useEffect(()=>{   
 
      getDocuments()
    // eslint-disable-next-line
    },[]);
  
  return (
    <div>
      {loading ?
      <DashboardLoader/>
      :
      <PdfViewerContributor
   
      docInfo={docDetails}
      refresh={getDocuments}
     
      />
}
    </div>
  )
}

export default ViewReportCont
