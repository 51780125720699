import React from 'react';
import './ReportContentView.scss';
import { BsDownload } from "react-icons/bs";
import { AiOutlineLink } from "react-icons/ai";
import bookmark from '../../../../assets/repBookmarkIcon.png'
import { LuClock3 } from "react-icons/lu";
import { LuCalendarPlus } from "react-icons/lu";
import { VscEye } from "react-icons/vsc";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const ReportContentView = ({docDetails,refreshDocuments}) => {
    const path = useLocation().pathname;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;

    // function formatDate(isoString) {
    //     const date = new Date(isoString);
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    //     const year = date.getFullYear();
    //     return `${day}/${month}/${year}`;
    // }

    function formatDateLong(isoString) {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    

    const viewData = [
        {
            subheader:"Title",
            para:docDetails.title
        },
        {
            subheader:"Description",
            para:docDetails.description
        },
        {
            subheader:"Tags",
            para: docDetails.industries.map(industry => industry.name).join(', ')
        },
        {
            subheader:"Author",
            para:docDetails.author
        },
        {
            subheader:"Publication Year",
            para:docDetails.publication_year
        },
        {
            subheader:"Document Type",
            para:docDetails.document_type
        },
        {
            subheader:"Source",
            para:docDetails.source
        },
        {
            subheader:"How did you get this document?",
            para:"I work there"
        }
    ] 

    const getDownload = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Authorization header
                },
            };

            const response = await axios.get(`${baseUrl}documents/consumer/${docDetails.id}/download`, config);
            console.log(response)
            const url = response.data.data.url;
           
            const link = document.createElement('a');
            link.href = url;
            link.download = docDetails.title; // Optional: Set a default file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
                } else {
                  alert(error.response.data.message);
                }
              } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
              } else {
                alert("An error occurred. Please try again later.");
              }
        }
    };

    const getBookmark = () => {

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
        
          axios.get(`${baseUrl}documents/consumer/${docDetails.id}/bookmark`, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              refreshDocuments();
             
            })
            .catch((error) => {
              
                if (error.response) {
                    if (error.response.status === 401) {
                      localStorage.clear(); // Clear local storage
                      window.location.href = '/login'; // Redirect to login page
                    } else {
                      alert(error.response.data.message);
                    }
                  } else if (error.request) {
                    alert("Check your connection. Unable to reach the server.");
                  } else {
                    alert("An error occurred. Please try again later.");
                  }
            });
          }
          catch(error){
            if (error.response) {
                alert(error.response.data.message);
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
               alert("An error occurred. Please try again later.");
            }
          }
    };

    function viewReportPdf (){
        if(path.startsWith("/contributor/contributions/")){
            window.location.href=`/user/viewdocument/pdf/contributor/${docDetails.id}`
        }
        else if(path.startsWith("/consumer/documents/home")){
            window.location.href=`/user/viewdocument/pdf/${docDetails.id}`
        }
    }


      
        
 
  return (

    <div className='report-view-cont'>

      <div className='report-view-header'>

        <div style={{width:'50%'}}>
            <h2>{docDetails.title}</h2>
        </div>

        <div className='report-view-btn-cont'>

            <div 
            onClick={()=>getDownload()}
            className='report-view-btn-one'>

                <BsDownload
                    size={20}
                    
                />
                <h4> Download(PDF)</h4>

            </div>

            <div className='report-view-btn-one'>

                <AiOutlineLink
                    size={20}
                />
                <h4> Copy Link </h4>
            </div>

            <div>
                <button
                onClick={()=>viewReportPdf()}
                className='report-view-btn-two'
                > Read Document</button>
            </div>
           
           
        </div>

    </div>
      
      <div className='report-view-content'>

        <div className='report-view-content-one'>

            {viewData.map((item,index)=> (

                 <div  className = "report-view-details" key={index}>
                    <h4>{item.subheader}</h4>
                    <p>{item.para}</p>
                 </div>

            ))}
           

        </div>

        <div className='report-view-content-two'>

            <div className='report-view-content-line'>
                <div>
                    <LuCalendarPlus
                    color='#4B4F57'
                    />
                    <h4>Date Created</h4>
                </div>

                <p>{formatDateLong(docDetails.created_at)}</p>

            </div>

            <div className='report-view-content-line'>
                <div>
                    <LuClock3
                    color='#4B4F57'
                    />
                    <h4>Last Updated</h4>
                </div>

                <p>{formatDateLong(docDetails.updated_at)}</p>


            </div>

            <div className='report-right-divider'>

            </div>

            <div className='report-view-content-line'>

                <div>
                    <BsDownload
                    color='#4B4F57'
                    />
                    <h4>Downloads</h4>
                   
                </div>

                <p>{docDetails.total_downloads}</p>

            </div>

            <div className='report-view-content-line'>
                <div>
                    <VscEye
                    color='#4B4F57'
                    />
                    <h4>Views</h4>
                </div>

                <p>{docDetails.total_views}</p>

            </div>

            <div 
            onClick={()=>getBookmark()}
            className='report-bookmark'>

                <div>
                    <img src={bookmark} alt="icon" />
                   
                </div>
                {docDetails.is_bookmarked ? 
                <h4>Remove from bookmarks</h4>
                : <h4>Save to bookmarks</h4>
                }
            </div>
            
        </div>

      </div>

    </div>
  )
}

export default ReportContentView
