import React,{useState} from 'react';
import './PdfViewer.scss';
import report from '../../../../assets/reportpdf.png';
import { PiWarningBold } from "react-icons/pi";
import { AiOutlineLink } from "react-icons/ai";
import { RxDownload } from "react-icons/rx";
import { FiBookmark } from "react-icons/fi";
import axios from 'axios';
import { useSelector } from 'react-redux';
import closeIcon from '../../../../assets/greysclose.svg';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import BaseButton from '../../../Onboarding/Reusables/BaseButton/BaseButton';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 448,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  outline: "none",
  padding:"16px 32px 32px",
  // overflowY:"auto"
 
};

const PdfViewer = ({docInfo,refreshDoc,pdfUrl}) => {
    const [rejection_reason, setRejectionReason] = useState("");
    const [reportloading, setReportLoading] = useState(false);
    const [open, setOpen] = useState(false); 
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
   
    function formatDateLong(isoString) {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }


    const getDownload = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Authorization header
                },
            };

            const response = await axios.get(`${baseUrl}documents/consumer/${docInfo.id}/download`, config);
            const url = response.data.data.url;

            const link = document.createElement('a');
            link.href = url;
            // link.download = docInfo.title; // Optional: Set a default file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
                } else {
                  alert(error.response.data.message);
                }
              } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
              } else {
                alert("An error occurred. Please try again later.");
              }
        }
    };

    const getBookmark = () => {

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
        
          axios.get(`${baseUrl}documents/consumer/${docInfo.id}/bookmark`, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              refreshDoc();
             
            })
            .catch((error) => {
              
                if (error.response) {
                    if (error.response.status === 401) {
                      localStorage.clear(); // Clear local storage
                      window.location.href = '/login'; // Redirect to login page
                    } else {
                      alert(error.response.data.message);
                    }
                  } else if (error.request) {
                    alert("Check your connection. Unable to reach the server.");
                  } else {
                    alert("An error occurred. Please try again later.");
                  }
            });
          }
          catch(error){
            if (error.response) {
                alert(error.response.data.message);
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
               alert("An error occurred. Please try again later.");
            }
          }
    };

    const reportDocument = () => {

        try{ 
      
        setReportLoading(true);
        let body ={
            
                issue_description: rejection_reason,
              
        }
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
        
          axios.post(`${baseUrl}documents/consumer/${docInfo.id}/report`,body, config)
            .then((response) => {
            setReportLoading(false);
              console.log(response.data);
              alert(response.data.message)
              
            //   refreshDoc();
             
            })
            .catch((error) => {
               setReportLoading(false);
               if (error.response) {
                if (error.response.status === 401) {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
                } else {
                  alert(error.response.data.message);
                }
              } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
              } else {
                alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
                alert(error.response.data.message);
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
               alert("An error occurred. Please try again later.");
            }
          }
    };


    return (

        <div>
        <div className='pdf-backgd'>

            <div className='pdf-main-cont'>

                <div className='pdf-cont-header'>

                    <div className='pdf-header-left'>

                        <div>
                         <img src={report} alt="" />
                        </div>

                        <div>
                            <h4>{docInfo.title}</h4>
                            <p className='pdf-left-tag'>
                                <li>{docInfo.industries.map(industry => industry.name).join(', ')}</li>
                                <p>Last Updated: {formatDateLong(docInfo.updated_at)}</p>
                            </p>
                        </div>

                    </div>

                    <div className='pdf-header-btn'>

                        <div >
                            <h4
                            onClick={()=>window.location.href=`/consumer/documents/home/viewdocument/${docInfo.id}`}
                            className='pdf-header-close-btn'
                            >Close Preview</h4>
                        </div>

                        <div 
                         onClick={()=>setOpen(true)}
                        className='pdf-header-report-btn'>
                            <PiWarningBold
                            color='#D42620'
                            fontWeight={"600"}
                           
                            />
                            <h4>Report</h4>
                        </div>
                    </div>

                </div>


                <section className='pdf-section' >

                   

                    <div className='pdf-iframe-cont'>
                    
                    <iframe
                        src={pdfUrl}
                        onContextMenu={(e) => e.preventDefault()} // Disable right-click
                        title="PDF Viewer"
                        style={{pointerEvents:"none",width: '100%', height: '100vh', border: 'none',background:"#F6F6F6",padding:'40px' }}
                     >

                    </iframe>

                   
                    
                    </div>

                    <div>

                        <div className='pdf-icons'>
                            <AiOutlineLink
                            size={18}
                            />
                        </div>

                        <div 
                        onClick={()=>getDownload()}
                        className='pdf-icons'>
                            <RxDownload
                            
                            size={18}
                            />
                        </div>

                        <div className='pdf-icons'>
                            <FiBookmark
                            color={docInfo.is_bookmarked ? "#007EFF":""}
                            onClick={()=>getBookmark()}
                            size={18}
                            />
                        </div>

                    </div>
                </section>

                

                    
            </div>
     
        </div>

        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

        <div className='pdf-modal-cont'>
            
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <img onClick={() => setOpen(false)} src={closeIcon} alt="" />
            </div>

            <h4>Report Resource</h4>

            <p>We value your feedback on this report. If you have any concerns about its content, such as inaccuracies, unclear information, or potential copyright issues, please share your thoughts. Your input will help us ensure the highest quality and accuracy.</p>

            <textarea 
            name="" 
            id=""
            value={rejection_reason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder='Enter'
            >


            </textarea>

            {reportloading ? 
            
            <p style={{textAlign:'center'}}>Loading...</p> :
            <BaseButton
            
            title={"Report Document"}
            width={"100%"}
            height={"44px"}
            padding={"0.5em 1em"}
            onClick={()=>reportDocument()}
            />
            
            }
                    
            </div>

        </Box>
        </Modal>
        </div>
    );
};

export default PdfViewer;
